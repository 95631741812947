import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useCookies } from 'react-cookie';


const CookiesInner = styled.div`
  background: #FFFFFF;
  box-shadow: 0 9px 20px 0 rgb(0 0 0 / 30%);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  z-index: 100;

  @media (max-width: 600px) {
    padding: 20px 0;
  }

  .wrap {
    display: flex;
    align-items: center;
    justify-content: left;
    width: calc(100% - 100px);
    max-width: 1150px;
    margin: 0 auto;

    @media (max-width: 899px) {
      width: calc(100% - 50px);
    }

    @media (max-width: 600px) {
      display: block;
    }
  }

  p {
    font-family: Visuelt-Regular;
    font-size: 14px;
    color: #2D2D2D;

    a {
      color: #2D2D2D;
    }
  }

  button {
    margin: 0;
    margin-left: 30px;
    font-family: Visuelt-Medium;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
    width: fit-content;
    display: block;
    background: #1D3A5B;
    border-radius: 3px;
    padding: 10px 10px;
    border: 0;
    white-space: nowrap;
    cursor: pointer;

    @media (max-width: 600px) {
      margin-left: 0;
      margin-top: 10px;
    }
  }
`;

const CookiesPopup = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['cookiePopup']);
  const [isVisible, setVisible] = useState(true);


  const zavrit = () => {
    setCookie("cookiePopup", true);
    setVisible(false);
  }

  useEffect(() => {
    if (cookies.cookiePopup === "true") {
      setVisible(false);
    }
  }, []);


    return (
      isVisible &&
      <CookiesInner>
        <div className="wrap">
          <p>Wir verwenden Cookies zu Werbezwecken und um Ihr Erlebnis auf unseren Websites zu verbessern. <Link to="">Datenschutzerklärung</Link></p>
          <button onClick={() => zavrit()}>Alle akzeptieren</button>
        </div>
      </CookiesInner>
    );
  
  
  
}

export default CookiesPopup;
