import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { useLocation } from '@reach/router';


import Telefon from "../assets/img/telefon.svg";
import Zavrit from "../assets/img/zavrit.svg";
import Logo from "../assets/img/logo_new.svg";
import Sipka from "../assets/img/sipkaPoplatky.svg";


const MobileMenuInner = styled.div`

  .mobileMenuBackground {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.6);
    z-index: 100;
  }

  .mobileMenuWrapper {
    position: fixed;
    width: 100%;
    background: rgba(0,0,0,0.6);
    background: #fff;
    z-index: 101;
    max-height: calc(100vh);
    overflow-y: scroll;


    .fakeHeader {
      background: #fff;
      border-bottom: 1px solid #DEDEDE;
      z-index: 102;
      position: relative;
      height: 64px;

      .logo {
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        top: 20px;
        left: 25px;

        img {
          height: 28px;
        }
      }

      .zavrit {
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        top: 22px;
        right: 25px;

        img {
          width: 22px;
        }
      }
    }

    .phone {
      border-top: 1px solid #DEDEDE;
      padding: 18px 0;
      padding-left: 25px;

      img {
        width: 19px;
        vertical-align: text-top;
      }

      a {
        color: #717171;
        font-family: Visuelt-Regular, Helvetica;
        font-size: 16px;
        vertical-align: text-top;
        margin-left: 12px;

        span {
          color: #000000;
          font-family: Visuelt-Bold, Helvetica;
        }
      }
    }
  }
`;


const StyledNav = styled.nav`
  width: calc(100% - 50px);
  margin: 30px auto;

  a {
    display: block;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  button {
    cursor: pointer;

    img {
      margin-left: 7px;
      width: 10px;
      vertical-align: middle;
      transition: all 0.3s;
      transform: rotate(180deg);
    }
  }

  .isActive {
    img {
      transform: rotate(0deg);
      transition: all 0.3s;
    }
  }

  .label {
    font-family: Visuelt-Regular;
    font-size: 16px;
    color: #8c8c8c;
    margin-bottom: 20px;
  }

  .main {
    border-bottom: 1px solid #DEDEDE;
    padding-bottom: 15px;
    margin-bottom: 30px;

    a {
      font-family: Visuelt-Medium;
      font-size: 24px;
      color: black;
      margin-bottom: 10px;
    }

    button {
      display: block;
      background: 0;
      border: 0;
      padding: 0;
      font-family: Visuelt-Medium;
      font-size: 24px;
      color: black;
      margin-bottom: 10px;
    }

    .mainExpanded {
      margin-top: 20px;
      margin-bottom: 20px;

      a {
        font-size: 18px;
        font-family: Visuelt-Regular;
      }
    }
  }

  .other {
    a {
      font-size: 17px;
      color: black;
      margin-top: 10px;
    }

    button {
      font-size: 17px;
      color: black;
      background: none;
      border: none;
      padding: 0;
      margin-top: 10px;
    }

    .dalsiSluzby {
      border-top: 1px solid #DEDEDE;
      margin-top: 15px;

      a {
        font-size: 20px;
        font-family: Visuelt-Medium;
      }
    }
  }
`;

const activeStyles = {
  "textDecoration": "underline",
}


const ZavetiMenu = () => {
  const [dalsiSluzby, setDalsiSluzby] = useState(false);
  const location = useLocation();


  const toggleDalsiSluzby = () => {
    if (dalsiSluzby) {
      setDalsiSluzby(false);
    }

    else {
      setDalsiSluzby(true);
    }
  }

  return (
    <StyledNav>
      <div className="label">Závěti</div>

      <div className="main">
        <Link style={(location.pathname === "/zaveti/" || location.pathname === "/zaveti") ? activeStyles : null} to="/zaveti">Závěti online</Link>
        <Link style={(location.pathname === "/zaveti/cenik/" || location.pathname === "/zaveti/cenik") ? activeStyles : null} to="/zaveti/cenik">Ceník</Link>
        <Link to="https://zaveti.goodbye.cz/prihlaseni">Přihlásit se</Link>
      </div>

      <div className="other">
        <Link style={(location.pathname === "/poradna/" || location.pathname === "/poradna") ? activeStyles : null} to="/poradna">Poradna</Link>
        <Link style={(location.pathname === "/o-nas/" || location.pathname === "/o-nas") ? activeStyles : null} to="/o-nas">O nás</Link>

        <button className={(dalsiSluzby) && "isActive"} onClick={() => toggleDalsiSluzby()}>Další služby <img src={Sipka} alt="" /></button>

        {(dalsiSluzby) &&
          <div className="dalsiSluzby">
            <Link style={(location.pathname === "/" || location.pathname === "") ? activeStyles : null} to="/">Pohřební služba</Link>
            <Link style={(location.pathname === "/pohrebni-sluzby/" || location.pathname === "/pohrebni-sluzby") ? activeStyles : null} to="/pohrebni-sluzby">Vyhledávač pohřebních služeb</Link>
            <Link style={(location.pathname === "/parte/" || location.pathname === "/parte") ? activeStyles : null} to="/parte">Parte online</Link>
          </div>
        }
      </div>
    </StyledNav>
  )
}

const PohrbyMenu = () => {
  const location = useLocation();

  const [dalsiSluzby, setDalsiSluzby] = useState(false);

  const toggleDalsiSluzby = () => {
    if (dalsiSluzby) {
      setDalsiSluzby(false);
    }

    else {
      setDalsiSluzby(true);
    }
  }

  return (
    <StyledNav>
      <div className="label">Pohřební služba</div>

      <div className="main">
        <Link style={(location.pathname === "/" || location.pathname === "") ? activeStyles : null} to="/">Domů</Link>
        <Link style={(location.pathname === "/kremace-bez-obradu/" || location.pathname === "/kremace-bez-obradu") ? activeStyles : null} to="/kremace-bez-obradu">Kremace bez obřadu</Link>
        <Link style={(location.pathname === "/pohreb-s-obradem/" || location.pathname === "/pohreb-s-obradem") ? activeStyles : null} to="/pohreb-s-obradem">Pohřeb s obřadem</Link>
        <Link style={(location.pathname === "/netradicni-pohreb/" || location.pathname === "/netradicni-pohreb") ? activeStyles : null} to="/netradicni-pohreb">Netradiční pohřeb</Link>
        <Link style={(location.pathname === "/poradna/" || location.pathname === "/poradna") ? activeStyles : null} to="/poradna">Poradna</Link>
        <Link style={(location.pathname === "/o-nas/" || location.pathname === "/o-nas") ? activeStyles : null} to="/o-nas">O nás</Link>
      </div>

      <div className="other">
        <Link style={(location.pathname === "/prihlasit-se/" || location.pathname === "/prihlasit-se") ? activeStyles : null} to="/prihlasit-se">Přihlásit se</Link>
        <Link style={(location.pathname === "/pohrebni-sluzby/" || location.pathname === "/pohrebni-sluzby") ? activeStyles : null} to="/pohrebni-sluzby">Vyhledávač pohřebních služeb</Link>
        <Link style={(location.pathname === "/parte/" || location.pathname === "/parte") ? activeStyles : null} to="/parte">Parte online</Link>
        <Link style={(location.pathname === "/zaveti/" || location.pathname === "/zaveti") ? activeStyles : null} to="/zaveti">Závěti</Link>

        {/* <button className={(dalsiSluzby) ? "isActive" : ""} onClick={() => toggleDalsiSluzby()}>Další služby <img src={Sipka} alt="" /></button>

        {(dalsiSluzby) &&
          <div className="dalsiSluzby">
          </div>
        } */}
      </div>
    </StyledNav>
  )
}

const ObecneMenu = () => {

  const [zaveti, setZaveti] = useState(false);
  const [kremace, setKremace] = useState(false);

  const location = useLocation();


  const toggle = (podminka, funkce) => {
    if (podminka) {
      funkce(false);
    }
  
    else {
      funkce(true);
    }
  }

  return (
    <StyledNav>
      <div className="main">

        
        

        <button className={(kremace) && "isActive"} onClick={() => toggle(kremace, setKremace)}>Varianty pohřbů <img src={Sipka} alt="" /></button>
        {(kremace) &&
          
          <div className="mainExpanded">
            <Link style={(location.pathname === "/kremace-bez-obradu/" || location.pathname === "/kremace-bez-obradu") ? activeStyles : null} to="/kremace-bez-obradu">Kremace bez obřadu</Link>
            <Link style={(location.pathname === "/pohreb-s-obradem/" || location.pathname === "/pohreb-s-obradem") ? activeStyles : null} to="/pohreb-s-obradem">Pohřeb s obřadem</Link>
            <Link style={(location.pathname === "/netradicni-pohreb/" || location.pathname === "/netradicni-pohreb") ? activeStyles : null} to="/netradicni-pohreb">Netradiční pohřeb</Link>
          </div>
        }
        


        <button className={(zaveti) && "isActive"} onClick={() => toggle(zaveti, setZaveti)}>Závěti <img src={Sipka} alt="" /></button>
        {(zaveti) &&
          
          <div className="mainExpanded">
            <Link style={(location.pathname === "/zaveti/" || location.pathname === "/zaveti") ? activeStyles : null} to="/zaveti">Závěti online</Link>
            <Link style={(location.pathname === "/zaveti/cenik/" || location.pathname === "/zaveti/cenik") ? activeStyles : null} to="/zaveti/cenik">Ceník</Link>
            <Link to="https://zaveti.goodbye.cz/prihlaseni">Přihlásit se</Link>
          </div>
        }

        <Link style={(location.pathname === "/poradna/" || location.pathname === "/poradna") ? activeStyles : null} to="/poradna">Poradna</Link>

        <Link style={(location.pathname === "/o-nas/" || location.pathname === "/o-nas") ? activeStyles : null} to="/o-nas">O nás</Link>
      </div>

      <div className="other">
        <Link style={(location.pathname === "/prihlasit-se/" || location.pathname === "/prihlasit-se") ? activeStyles : null} to="/prihlasit-se">Přihlásit se</Link>
        <Link style={(location.pathname === "/pohrebni-sluzby/" || location.pathname === "/pohrebni-sluzby") ? activeStyles : null} to="/pohrebni-sluzby">Vyhledávač pohřebních služeb</Link>
        <Link style={(location.pathname === "/parte/" || location.pathname === "/parte") ? activeStyles : null} to="/parte">Parte online</Link>
      </div>
    </StyledNav> 
  )
}




const MobileMenu = ({ otevritMenu, jeOtevreny }) => {
  const location = useLocation();
  let url = typeof window !== 'undefined' ? window.location.href : '';
  url = url.replace(/^.*\/\/[^\/]+/, '');


  const renderMenu = (url) => {
    if (url.startsWith("/zaveti")) {
      return(<ZavetiMenu />);
    }
  
    else if (url.startsWith("/kremace-bez-obradu") || url.startsWith("/pohreb-s-obradem") || url.startsWith("/netradicni-pohreb")) {
      return(<PohrbyMenu />);
    }
  
    else {
      return(<ObecneMenu />);
    }  
  };

  


  return (
    <MobileMenuInner>
      {jeOtevreny ?
        <>
        <div className="mobileMenuWrapper">
          <div className="fakeHeader">
            <Link className="logo" to="/">
              <img src={Logo} alt="Logo eTanatorio" />
            </Link>
            <div onClick={() => otevritMenu()} onKeyDown={() => otevritMenu()} className="zavrit" role="button" tabIndex={0}>
              <img src={Zavrit} alt="Zavřít" />
            </div>
          </div>

          <div className="nav">
            {renderMenu(url)}
          </div>

          <div className="phone">
            <img src={Telefon} alt="Teléfono 24/7" />
            <a href="tel:315558136"><span>XXX XXX XXX</span> — Asistencia 24/7</a>
          </div>
        </div>
        <div className="mobileMenuBackground" onClick={() => otevritMenu()} onKeyDown={() => otevritMenu()} role="button" aria-label="Zavrit" tabIndex={0}></div>
        </>
      : null }
    </MobileMenuInner>
  )
}


export default MobileMenu;
