import React, { useState } from "react";
import Header from "./Header";
import SimpleHeader from "./SimpleHeader";
import Footer from "./Footer";
import MobileMenu from "./MobileMenu";
import styled from "styled-components";
import CookiesPopup from "./CookiesPopup";

const Wrapper = styled.div`
`;


export default function Layout({ children, noFooter, simpleHeader, noSubmenu, showPhone, isLoggedIn, logOut }) {

  const [ jeOtevreneMenu, setJeOtevreneMenu] = useState(false);

  const otevritMenu = () => {
    if (jeOtevreneMenu) {
      setJeOtevreneMenu(false);
    }

    else {
      setJeOtevreneMenu(true);
    }
  }


  return (
    <div>
      {(simpleHeader) ?
        <SimpleHeader isLoggedIn={isLoggedIn} showPhone={showPhone} logOut={logOut} />
      : 
        <>
          <MobileMenu jeOtevreny={jeOtevreneMenu} otevritMenu={otevritMenu}/>
          <Header noSubmenu={noSubmenu} otevritMenu={otevritMenu} />
        </>
      }
      {/* <CookiesPopup /> */}
      <Wrapper>
        {children}
      </Wrapper>
      {(noFooter) ? null : <Footer />}
    </div>
  )
}
